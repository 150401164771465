"use client";

import { SessionProvider } from "next-auth/react";
import { TooltipProvider } from "~/components/v2/Tooltip";
import { SidebarStateProvider } from "~/hooks/useSidebarState";

import { ApolloProvider } from "@apollo/client";
import {
  isServer,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import apolloClient from "~/bff/apolloClient";
import { AdvanceCandidateProvider } from "~/src/app/company/(dashboard)/(applications)/pipeline/components/advance-candidate-context";
import { SearchBarProvider } from "~/components/v2/jobs/search-bar/search-bar-context";
import { WorkspaceProvider } from "./workspace/workspace-provider";
import { NavbarProvider } from "~/scalis-components/core/navbar/navbar-context";

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000,
      },
    },
  });
}

let browserQueryClient: QueryClient | undefined = undefined;

function getQueryClient() {
  if (isServer) {
    return makeQueryClient();
  } else {
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}

interface ProvidersProps {
  children: React.ReactNode;
}

export default function Providers({ children }: ProvidersProps) {
  const queryClient = getQueryClient();

  return (
    <ApolloProvider client={apolloClient}>
      <SessionProvider>
        <NavbarProvider>
          <WorkspaceProvider>
            <SidebarStateProvider>
              <TooltipProvider>
                <SearchBarProvider>
                  <QueryClientProvider client={queryClient}>
                    <AdvanceCandidateProvider>
                      {children}
                    </AdvanceCandidateProvider>
                  </QueryClientProvider>
                </SearchBarProvider>
              </TooltipProvider>
            </SidebarStateProvider>
          </WorkspaceProvider>
        </NavbarProvider>
      </SessionProvider>
    </ApolloProvider>
  );
}
