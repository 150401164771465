import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { signOut } from "next-auth/react";
import logger from "~/utils/logger";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions, message, locations, path }) => {
      const originalError: any = extensions?.originalError;
      const code = extensions?.code || originalError?.extensions?.code;

      if (code === "TOKEN_EXPIRED" || code === "TOKEN_EXPIRED" || code === "TOKEN_NOT_VERIFIED" || code === "INVALID_TOKEN") {
        signOut();
      }
      logger.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }
  if (networkError) logger.error(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({
  uri: `${process.env.NEXT_PUBLIC_APP_URL}/api/graphql`,
  credentials: "include",
});

const authLink = setContext((_, { headers }) => {
  return {
    headers,
  };
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({ addTypename: false }),
});

export default apolloClient;
