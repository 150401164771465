"use client";

import { Session } from 'next-auth';
import { getSession, signOut } from 'next-auth/react';
import { useCallback, useEffect } from 'react';
import jwt from "jsonwebtoken";


export default function SessionTimeout() {
  const handleTokenExpired = async () => {
    await signOut({
      callbackUrl: "/",
    });
  };

  const logoutOnTokenExpiration = useCallback((session: Session) => {
    if (!session?.user?.accessToken) {
      console.error("Access token is missing.");
      return;
    }

    const decodedToken: { exp?: number } | null = jwt.decode(session.user.accessToken) as { exp?: number } | null;

    if (!decodedToken?.exp) {
      console.error("Could not extract expiration time from access token.");
      return;
    }
  
    const timeUntilExpiration = decodedToken.exp * 1000 - Date.now();

    if (timeUntilExpiration > 0) {
      const MAX_TIMEOUT = 7 * 24 * 60 * 60 * 1000; // 7 days in ms
  
      setTimeout(
        () => {
          handleTokenExpired();
        },
        timeUntilExpiration > MAX_TIMEOUT
          ? MAX_TIMEOUT
          : timeUntilExpiration,
      );
    } else {
      handleTokenExpired();
    }
  }, []);

  useEffect(() => {
    getSession().then(session => {
      if (session && session.expires) {
        logoutOnTokenExpiration(session);
      }
    });
  }, []);

  return null;
}
