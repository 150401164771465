"use client";

import logger from "~/utils/logger";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from '@datadog/browser-logs';
import { useEffect } from 'react';
import { useSession } from "next-auth/react";

let isDatadogInitialized = false;

function initializeDatadog() {
  if (
    isDatadogInitialized ||
    process.env.NEXT_PUBLIC_DATADOG_ENABLED !== "true"
  ) {
    return;
  }

  isDatadogInitialized = true;

  const url = process.env.APP_URL ?? process.env.NEXT_PUBLIC_APP_URL;
  const applicationId = process.env.NEXT_PUBLIC_DATADOG_APP_ID;
  const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;
  const datadogEnv = process.env.NEXT_PUBLIC_DATADOG_ENV;

  if (!applicationId || !clientToken || !url || !datadogEnv) {
    logger.error("Missing Datadog RUM configuration");
    return;
  }

  logger.info(`Initializing Datadog RUM for ${url}`);

  if (process.env.NODE_ENV === "production") {
    datadogRum.init({
      applicationId,
      clientToken,
      site: "datadoghq.com",
      service: `scalis-${datadogEnv}`,
      env: datadogEnv,
      sessionSampleRate: datadogEnv === "production" ? 100 : 10,
      sessionReplaySampleRate: datadogEnv === "production" ? 20 : 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "allow",
      allowedTracingUrls: [url],
    });
  }

  datadogLogs.init({
    clientToken,
    site: "datadoghq.com",
    service: `scalis-${datadogEnv}`,
    env: datadogEnv,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });
}

export default function DatadogInit() {
  useEffect(() => {
    initializeDatadog();
  }, []);

  const { data: session } = useSession();
  const user = session?.user;

  useEffect(() => {
    if (isDatadogInitialized && user) {
      datadogRum.setUser({
        id: user.id.toString(),
        name: user.email,
        email: user.email,
      });
    }
  }, [user]);

  return null;
}
